import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkHeading } from "@egds/react-core/text";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkCardContentSection, UitkCard } from "@egds/react-core/cards";
import { useLocalization } from "@shared-ui/localization-context";
import { ViewExtraLarge, ViewLarge, ViewMedium, Viewport, ViewSmall, ViewSizes } from "@shared-ui/viewport-context";
import { LandingSearchHeroProps } from "./typings";
import {
  LodgingSearchForm as SearchForm,
  SearchFormLayout,
  LodgingSearchFormOptions as SearchFormOptions,
  LodgingSearchFormInputs as SearchFormInputs,
} from "@shared-ui/retail-search-tools-product";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { getFilterQueryParams } from "./utils/search-form-utils";
import { UitkSheetContent, UitkSheet, UitkSheetTransition } from "@egds/react-core/sheet";
import CtaMobile from "./components/CtaMobile";
import { UitkSticky } from "@egds/react-core/sticky";
interface WrapperSearchFormProps {
  viewSize: ViewSizes;
}
export const LandingSearchHero = withStores(
  "context",
  "compositionStore",
  "page"
)(
  observer((props: LandingSearchHeroProps) => {
    const { templateComponent, context, compositionStore, page } = props;
    if (!templateComponent) {
      return null;
    }
    const {
      enableAutoOpenCalender,
      includePageHeading,
      includePageSubHeading,
      sticky,
      enableShadow,
      fieldToAutoSubmitOnChange,
      openPlayBackInSRP = false,
      withBackgroundImage = false,
    } = templateComponent.config;
    const pageHeading = compositionStore?.pageHeading;
    const pageSubHeading = compositionStore?.pageSubHeadline;
    const classNames = enableShadow ? "elevation" : "";
    const getRegionId = () => {
      if (context?.searchContext?.location) {
        return context.searchContext.location.id.toString();
      }
      return null;
    };
    const prefillInputs = {
      propertySearchCriteriaInput: {
        primary: { destination: { regionId: getRegionId() }, rooms: [] },
      },
    };
    const { formatText } = useLocalization();
    const componentName = templateComponent?.type || "landing-search-hero";
    const title = formatText("hotels.info.dateless.searchForm.heading.title");

    const filterParams = getFilterQueryParams(context);
    const openPlaybackParam = openPlayBackInSRP ? "openPlayBack=true" : "";
    const additionalQueryParamsValue = [filterParams, openPlaybackParam].filter(Boolean).join("&");

    const searchFormProps: SearchFormOptions & { inputs: SearchFormInputs } = {
      inputs: prefillInputs,
      autoOpenCalendar: enableAutoOpenCalender,
      redirectToSearchResultOnSubmit: true,
      additionalAdapterFields: { xPageId: page?.pageId ?? "", popularFilter: true },
      additionalQueryParams: additionalQueryParamsValue,
      autoSubmitOnFieldChangeConfig: {
        onDateFieldChange: fieldToAutoSubmitOnChange === "date-field",
      },
    };

    const WrapperSearchFormMobile: React.FC = () => {
      const searchFormRef = React.useRef(null);
      const [showCTA, setshowCTA] = React.useState(false);
      const triggerRef = React.useRef(null);

      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const entry = entries.shift();
        const isFixed = !entry || !entry?.isIntersecting;
        setshowCTA(isFixed);
      };

      React.useEffect(() => {
        let observer: IntersectionObserver;
        if (searchFormRef?.current) {
          observer = new IntersectionObserver(handleIntersection, {
            threshold: 0,
          });
          observer.observe(searchFormRef.current as Element);
        }
        return () => {
          if (searchFormRef?.current) {
            observer.unobserve(searchFormRef.current as Element);
          }
        };
      }, []);
      const contentDiv = () => {
        return (
          <div>
            <div>
              {includePageHeading && pageHeading && (
                <UitkSpacing
                  margin={{
                    blockend: "three",
                  }}
                >
                  <UitkHeading tag="h1" size={4} style={{ color: withBackgroundImage ? "white" : undefined }}>
                    {pageHeading}
                  </UitkHeading>
                </UitkSpacing>
              )}
            </div>
            <div>
              {includePageSubHeading && pageSubHeading && (
                <UitkSpacing
                  margin={{
                    blockend: "three",
                  }}
                >
                  <UitkHeading tag="h2" size={6} style={{ color: withBackgroundImage ? "white" : undefined }}>
                    {pageSubHeading}
                  </UitkHeading>
                </UitkSpacing>
              )}
            </div>
            <div data-testid="landing-search-hero" ref={searchFormRef}>
              <SearchForm {...searchFormProps} />
            </div>
          </div>
        );
      };

      return (
        <>
          <UitkSpacing padding="unset" margin="unset">
            <UitkSheetTransition isVisible={showCTA}>
              <UitkSheet isVisible={showCTA} type="bottom" triggerRef={triggerRef}>
                <UitkSheetContent padded={false}>
                  <CtaMobile
                    moduleName={`${componentName}-sticky`}
                    submitButtonText={formatText("wizard.sticky.cta.label")}
                    scrollTarget={searchFormRef}
                  />
                </UitkSheetContent>
              </UitkSheet>
            </UitkSheetTransition>
          </UitkSpacing>
          {withBackgroundImage ? (
            <UitkLayoutPosition type={"relative"} style={{ width: "100%" }} position={{ top: "four" }} cloneElement>
              <div>{contentDiv()}</div>
            </UitkLayoutPosition>
          ) : (
            <UitkCard
              style={{ paddingBlock: "1.325rem", paddingInline: "1rem", marginBottom: "0.75rem", borderRadius: "0px" }}
            >
              {contentDiv()}
            </UitkCard>
          )}
        </>
      );
    };

    const WrapperSearchForm: React.FC<WrapperSearchFormProps> = ({ viewSize }) => {
      const [isSticky, setIsSticky] = React.useState(false);
      let layoutGridStyle;
      let spacingStyle;
      switch (viewSize) {
        case ViewSizes.MEDIUM:
          layoutGridStyle = !isSticky
            ? { width: "25rem", maxHeight: "22rem", paddingLeft: "0.75rem", marginBottom: "1.50rem" }
            : { padding: "0px" };
          spacingStyle = !isSticky ? { marginBlockEnd: "-2rem" } : {};
          break;
        case ViewSizes.LARGE:
          layoutGridStyle = !isSticky
            ? { width: "25rem", maxHeight: "22rem", paddingLeft: "0.75rem", marginBottom: "2rem" }
            : { padding: "0px" };
          spacingStyle = !isSticky ? { marginBlockEnd: "-2.75rem" } : {};
          break;
        case ViewSizes.EXTRA_LARGE:
          layoutGridStyle = !isSticky ? { width: "25rem", padding: "0px" } : { padding: "0px" };
          spacingStyle = !isSticky ? { marginBlockEnd: "-1.5rem" } : {};
          break;
        default:
          break;
      }
      const form = (
        <UitkLayoutPosition
          type={isSticky ? "relative" : "absolute"}
          position={!isSticky ? { bottom: "minus-twelve" } : {}}
          cloneElement
        >
          <UitkLayoutGrid style={layoutGridStyle}>
            <UitkLayoutGridItem>
              <UitkCard
                overflow
                className={classNames}
                style={isSticky ? { borderStartStartRadius: "0px", borderStartEndRadius: "0px" } : {}}
              >
                <UitkSpacing padding={!isSticky ? "six" : "three"} style={spacingStyle}>
                  <UitkCardContentSection>
                    <div>
                      {includePageHeading && pageHeading && !isSticky && (
                        <UitkSpacing
                          margin={{
                            blockend: "three",
                          }}
                        >
                          <UitkHeading tag="h1" size={4}>
                            {pageHeading}
                          </UitkHeading>
                        </UitkSpacing>
                      )}
                    </div>
                    <div>
                      {includePageSubHeading && pageSubHeading && !isSticky && (
                        <UitkSpacing margin={{ blockend: "four" }}>
                          <div>
                            <UitkHeading tag="h2" size={6}>
                              {pageSubHeading}
                            </UitkHeading>
                          </div>
                        </UitkSpacing>
                      )}
                    </div>
                    <div>
                      {isSticky && (
                        <UitkSpacing padding={{ inline: "three" }}>
                          <UitkHeading tag="h1" size={5}>
                            {title}
                          </UitkHeading>
                        </UitkSpacing>
                      )}
                    </div>
                    <UitkSpacing padding={!isSticky ? undefined : { block: "three" }}>
                      <div data-testid={isSticky ? "landing-search-hero-sticky" : "landing-search-hero"}>
                        <SearchForm
                          {...searchFormProps}
                          layout={isSticky ? SearchFormLayout.IN_LINE : SearchFormLayout.STACKED}
                        />
                      </div>
                    </UitkSpacing>
                  </UitkCardContentSection>
                </UitkSpacing>
              </UitkCard>
            </UitkLayoutGridItem>
          </UitkLayoutGrid>
        </UitkLayoutPosition>
      );
      return (
        <div style={{ padding: "0px" }}>
          {sticky ? (
            <UitkSticky
              zIndex={400}
              onFixed={(isFixed) => {
                setIsSticky(isFixed);
              }}
            >
              {form}
            </UitkSticky>
          ) : (
            <>{form}</>
          )}
        </div>
      );
    };

    return (
      <Viewport>
        <ViewSmall>
          <WrapperSearchFormMobile />
        </ViewSmall>
        <ViewMedium>
          <WrapperSearchForm viewSize={ViewSizes.MEDIUM} />
        </ViewMedium>
        <ViewLarge>
          <WrapperSearchForm viewSize={ViewSizes.LARGE} />
        </ViewLarge>
        <ViewExtraLarge>
          <WrapperSearchForm viewSize={ViewSizes.EXTRA_LARGE} />
        </ViewExtraLarge>
      </Viewport>
    );
  })
);

LandingSearchHero.displayName = "LandingSearchHero";

export default LandingSearchHero;

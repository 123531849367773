import {
  getHSRFilterCode,
  getHSRFilters,
  getHSRStarRatingCode,
  getPropertyTypeCode,
  getBEXApiFilters,
} from "src/components/utility/ContextUtil";

import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

enum HSRFilterNames {
  AMENITIES = "amenities",
  TRAVELER_TYPE = "travelerType",
  SORT = "sort",
  PAYMENT_TYPE = "paymentType",
  PROPERTY_STYLE = "propertyStyle",
  MEAL_PLAN = "mealPlan",
}

const SORT_DISTANCE_VALUE = "distance";

export const getFilterQueryParams = (context: ExtendedContextStore): string => {
  let filterQueryParams = "";
  const hsrFilterCode = getHSRFilterCode(context);
  const bexApiFilters = getBEXApiFilters(context);
  const hsrFilters = getHSRFilters(context);
  const hsrStarRatingCode = getHSRStarRatingCode(context);
  const propertyType = getPropertyTypeCode(context);

  const getHSRFilterValue = (key: HSRFilterNames, defaultValue?: string) => {
    return hsrFilters ? hsrFilters[key] : defaultValue;
  };
  const getbexApiFiltersValue = (key: HSRFilterNames, defaultValue?: string) => {
    return bexApiFilters ? bexApiFilters[key] : defaultValue;
  };

  const sort = getHSRFilterValue(HSRFilterNames.SORT, getSortFilterValue(context));
  const amenity = getbexApiFiltersValue(HSRFilterNames.AMENITIES);
  const paymentType = getHSRFilterValue(HSRFilterNames.PAYMENT_TYPE, hsrFilterCode);
  const propertyStyle = getHSRFilterValue(HSRFilterNames.PROPERTY_STYLE);
  const travelerType = getHSRFilterValue(HSRFilterNames.TRAVELER_TYPE);
  const mealPlan = getbexApiFiltersValue(HSRFilterNames.MEAL_PLAN);

  filterQueryParams = `GOTO=HOTSEARCH&SearchType=Place&SearchArea=City&needUTF8Decode=true`;

  if (context.langId) {
    filterQueryParams += `&lang=${context.langId}`;
  }

  if (sort) {
    filterQueryParams += `&sort=${sort}`;
  }

  if (amenity) {
    filterQueryParams += `&amenities=${amenity}`;
  }
  if (paymentType) {
    filterQueryParams += `&paymentType=${paymentType}`;
  }
  if (travelerType) {
    filterQueryParams += `&travelerType=${travelerType}`;
  }
  if (mealPlan) {
    filterQueryParams += `&mealPlan=${mealPlan}`;
  }
  if (hsrStarRatingCode) {
    filterQueryParams += `&star=${hsrStarRatingCode}`;
  }
  if (propertyType) {
    filterQueryParams += `&lodging=${propertyType}`;
  }
  if (propertyStyle) {
    filterQueryParams += `&propertyStyle=${propertyStyle}`;
  }

  return filterQueryParams;
};

const getSortFilterValue = (context: ExtendedContextStore) => {
  const { searchContext } = context;
  const isAirport = searchContext.location && searchContext.location.type === "airport";
  const isPOI = searchContext.location && searchContext.location.type === "point_of_interest";
  const lob = searchContext.lob;

  if (lob === "vacation_rental") {
    return lob.toUpperCase();
  }

  return isAirport || isPOI ? SORT_DISTANCE_VALUE : "";
};

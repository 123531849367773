// Libraries
import * as React from "react";
import { UitkSpacing } from "@egds/react-core/spacing";

import { UitkPrimaryButton, UitkButtonSize } from "@egds/react-core/button";
import { FlexClickTracker } from "src/components/utility/analytics/FlexClickTracker/FlexClickTracker";

interface CtaMobileProps extends React.HTMLAttributes<HTMLDivElement> {
  moduleName: string;
  linkName?: string;
  rfrr?: string;
  submitButtonText: string;
  scrollTarget: React.RefObject<HTMLElement>;
}

const WrapperTrackedButton = FlexClickTracker(UitkPrimaryButton);

/**
 * Sticky Call to Action button for the Wizard. When you click it brings focus to Wizard
 *
 * @param {boolean} scrollTarget - Target Element
 * @param {string} moduleName - The module parent name
 * @param {string} submitButtonText - The button label text
 * @param {React.RefObject<HTMLElement>} scrollTarget - The reference to the target element that brings you into it
 */
export const CtaMobile: React.FC<CtaMobileProps> = ({
  moduleName,
  linkName,
  submitButtonText,
  scrollTarget,
  rfrr,
  ...htmlDivHTMLProps
}: CtaMobileProps) => {
  const scrollToTargetRef = () => {
    const element = scrollTarget.current && scrollTarget.current && scrollTarget.current.parentElement;

    if (element) {
      element.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
      element.focus();
    }
  };

  return (
    <UitkSpacing padding="three">
      <div data-testid="choose-date-sticky-button" {...htmlDivHTMLProps}>
        <WrapperTrackedButton
          tag="button"
          rfrr={rfrr || "hotel.wizard.cta"}
          linkName={linkName || "Call to action click event in the LandingSearchHero component"}
          moduleName={moduleName}
          className="wizard-cta-btn"
          size={UitkButtonSize.SMALL}
          isFullWidth
          data-testid="wizard-cta-button"
          onClick={scrollToTargetRef}
        >
          {submitButtonText}
        </WrapperTrackedButton>
      </div>
    </UitkSpacing>
  );
};

CtaMobile.displayName = "CtaMobile";

export default CtaMobile;
